.Virtual {
  display: flex;
  justify-content: space-around;
  margin-top: 6rem;
  margin-bottom: 6rem;
  padding: 2rem;
}

.left {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
}

.left > :nth-child(1) {
  font-size: 2rem;
  text-transform: uppercase;
  display: block;
  font-weight: bold;
}

.left > :nth-child(2) {
  font-size: 1.6rem;
  text-transform: uppercase;
  display: block;
  width: 20rem;
}
.left > :nth-child(3) {
  font-size: 1.6rem;
  font-weight: bold;
}

.left > img {
  width: 10rem;
  bottom: 3rem;
  position: absolute;
}
.wrapper {
  width: 30rem;
}
.wrapper img {
  height: 104% !important;
}
@media screen and (max-width: 856px) {
  .Virtual {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 1rem;
    text-align: center;
  }
  .wrapper img {
    height: 100% !important;
  }
}

@media screen and (max-width: 640px) {
  .wrapper img {
    transform: scale(0.6);
  }
  .left > :nth-child(1) {
    font-size: 1.5rem;
  }
  .left > :nth-child(2) {
    font-size: 1.4rem;
  }
  .left > :nth-child(3) {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 446px) {
  .Virtual {
    max-width: 80%;
    margin-bottom: 0;
  }
  .left > :nth-child(2) {
    font-size: 0.8rem;
    max-width: 70%;
    margin: auto;
  }
  .Virtual > :nth-child(2) {
    max-width: 100%;
  }
  .wrapper {
    width: 13rem;
  }

  .left > img {
    transform: scale(0.7);
    bottom: 2.5rem;
  }
  .wrapper img {
    transform: scale(1);
  }
}
